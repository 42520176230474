// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-consulting-js": () => import("./../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-enterprise-iot-assistance-js": () => import("./../src/pages/enterprise-iot-assistance.js" /* webpackChunkName: "component---src-pages-enterprise-iot-assistance-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-lte-js": () => import("./../src/pages/private-lte.js" /* webpackChunkName: "component---src-pages-private-lte-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-virtual-evolved-packet-core-js": () => import("./../src/pages/virtual-evolved-packet-core.js" /* webpackChunkName: "component---src-pages-virtual-evolved-packet-core-js" */)
}

